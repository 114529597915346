<template>
  <div>
    <!--begin::Dashboard-->
    <div class="row">
      <div class="col-12">
        <div class="card card-custom card-stretch gutter-b">
          <!--begin::Header-->
          <div class="card-header border-0 pt-5">
            <h3 class="card-title align-items-start flex-column">
              <span class="card-label font-weight-bolder text-dark">
                Prestations
              </span>
            </h3>
            <div class="card-toolbar">
              <b-button v-if="csvLoading" variant="secondary" class="font-weight-bolder mr-3 d-flex flex-row align-items-center" disabled>
                <b-spinner small /> Télécharger en Excel
              </b-button>
              <b-button v-else @click.prevent="exportCsv" variant="secondary" class="font-weight-bolder mr-3 d-flex flex-row align-items-center">
                <i class="fas fa-file-download mr-4" /> Télécharger en Excel
              </b-button>

              <a href="#" v-if="hasUserRights([{ section: 'services', rights: ['create'] }])" @click.prevent="newItem" class="btn btn-primary font-weight-bolder d-flex flex-row align-items-center">
                <i class="fas fa-folder-plus mr-4"/> Nouvelle prestation
              </a>
            </div>
          </div>
          <!--end::Header-->
          <!--begin::Body-->
          <div class="card-body py-4">
            <!--begin::Table-->
            <vue-good-table
              mode="remote"
              :total-rows="totalRecords"
              :is-loading.sync="isTableLoading"
              :pagination-options="{ enabled: true }"
              :sort-options="{ enabled: false, initialSortBy: serverParams.sort }"
              :rows="rows"
              :columns="columns"
              @on-page-change="onPageChange"
              @on-sort-change="onSortChange"
              @on-column-filter="onColumnFilter"
              @on-per-page-change="onPerPageChange"
              styleClass="custom-table"
            >
              <div slot="loadingContent" class="text-center">Chargement...</div>
              <div slot="emptystate" class="text-center">
                Aucun élément à afficher
              </div>
              <template slot-scope="props" slot="table-row">

                <!-- Column: Action -->
                <span v-if="props.column.field === 'action'">
                    <a href="#" v-if="hasUserRights([{ section: 'services', rights: ['update'] }])" @click.prevent="editItem(props.row.id)" class="mr-5">
                        <i class="fas fa-pencil-alt text-primary"/>
                    </a>
                    <a href="#" v-if="hasUserRights([{ section: 'services', rights: ['delete'] }])" @click.prevent="deleteItem(props.row.id)">
                        <i class="fas fa-trash-alt text-danger"/>
                    </a>
                </span>

                <!-- Column: Common -->
                <span v-else>
                  {{ props.formattedRow[props.column.field] }}
                </span>
              </template>

              <!-- pagination -->
              <template slot="pagination-bottom" slot-scope="props">
                <div class="d-flex justify-content-between flex-wrap">
                  <div class="d-flex align-items-center mb-0 mt-1">
                    <b-form-select
                      v-model="serverParams.perPage"
                      :options="['10', '25', '50', '100']"
                      class="mr-2 custom-select-sm"
                      @input="
                        (value) => props.perPageChanged({ currentPerPage: value })
                      "
                    />
                    <span class="text-nowrap">
                      prestations par page
                    </span>
                  </div>
                  <div>
                    <b-pagination
                      :value="serverParams.page"
                      :total-rows="props.total"
                      :per-page="serverParams.perPage"
                      first-number
                      last-number
                      align="right"
                      prev-class="prev-item"
                      next-class="next-item"
                      class="mt-1 mb-0"
                      @input="(value) => props.pageChanged({ currentPage: value })"
                    >
                      <template #prev-text>
                        <i class="fas fa-chevron-left"></i>
                      </template>
                      <template #next-text>
                        <i class="fas fa-chevron-right"></i>
                      </template>
                    </b-pagination>
                  </div>
                </div>
              </template>
            </vue-good-table>
          </div>
        </div>
      </div>
    </div>
    <!--end::Dashboard-->

    <b-modal v-model="modal.show" :title="modal.title" hide-footer no-close-on-backdrop>
     <div class="d-block">
        <b-form id="modal-form" @submit.prevent="onSubmitItem">
            <b-form-group label="Référence :">
                <b-form-input
                    v-model="modal.item.reference"
                    type="text"
                    placeholder="Référence"
                    required
                />
            </b-form-group>
            <b-form-group label="Nom :">
                <b-form-input
                    v-model="modal.item.name"
                    type="text"
                    placeholder="Nom"
                    required
                />
            </b-form-group>
            <b-form-group label="Prix (HT) :">
                <b-form-input
                    v-model="modal.item.price"
                    type="number"
                    min="0"
                    step="0.01"
                    placeholder="Prix (HT)"
                    required
                />
            </b-form-group>

            <div class="text-right">
                <b-button class="mr-2" @click.prevent="hideModal" variant="light-primary">Annuler</b-button>
                <b-button type="submit" variant="primary">Sauvegarder</b-button>
            </div>
        </b-form>
     </div>
   </b-modal>
  </div>
</template>

<script>
import ApiService from "@/core/services/api.service";
import { mapGetters } from "vuex";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

export default {
  name: "prestations",
  data() {
    return {
      columns: [
        {
          label: "Référence",
          field: "reference",
          sortable: true,
          filterOptions: { enabled: true, placeholder: "Rechercher..." },
        },
        {
          label: "Nom",
          field: "name",
          sortable: true,
          filterOptions: { enabled: true, placeholder: "Rechercher..." },
        },
        {
          label: "Prix (HT)",
          field: "price",
          formatFn: (e) => `${e.toFixed(2)}€`,
          sortable: true,
          filterOptions: { enabled: true, placeholder: "Prix HT", fullMatch: true },
        },
        {
          label: "Prix TTC",
          field: (rowObj) => { return (rowObj.price * 1.2).toFixed(2) },
          formatFn: (e) => `${e}€`,
          sortable: false
        },
        { label: "", field: "action", width: "80px" },
      ],
      rows: [],
      recents: [],
      totalRecords: 0,
      isTableLoading: true,
      csvLoading: false,
      serverParams: {
        columnFilters: {},
        sort: {
          field: "name",
          type: "asc",
        },
        page: 1,
        perPage: 10,
      },
      modal: {
          show: false,
          title: "Nouvelle prestation",
          item: {
            id: null,
            reference: null,
            name: null,
            price: null,
          },
      }
    };
  },
  computed: {
    ...mapGetters(["hasUserRights"]),
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Prestations" }]);
  },
  beforeUnmount() {
    this.$root.$off("bv::modal::shown"); 
  },
  methods: {
    hideModal() {
        this.modal.show = false;
    },
    newItem() {
        this.modal.item = { id: null, reference: null, name: null, price: null };
        this.modal.title = "Nouvelle prestation";
        this.modal.show = true;
    },
    editItem(id) {
        ApiService
            .get("/services", id)
            .then(({ data }) => {
                this.modal.item = { ...data };
                this.modal.title = "Edition de la prestation";
                this.modal.show = true;
            });
    },
    onSubmitItem() {
        if (this.modal.item.id) {
            ApiService
                .update(`/services`, this.modal.item.id, this.modal.item)
                .then(() => {
                    this.fetchItemsFromApi();
                    this.modal.show = false;
                });
        } else {
            ApiService
                .post(`/services`, this.modal.item)
                .then(() => {
                    this.fetchItemsFromApi();
                    this.modal.show = false;
                });
        }
    },
    deleteItem(id) {
        this.$swal.fire({
            title: 'Êtes-vous sûr ?',
            text: "Souhaitez-vous supprimer cette prestation ? Cette action est irréversible",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#d33',
            confirmButtonText: 'Supprimer',
        }).then(({ isConfirmed }) => {
            if (isConfirmed) {
                ApiService
                    .delete(`/device-categories/${id}`)
                    .then(() => {
                        this.$swal.fire('Supprimé !', 'La prestation a bien été supprimée', 'success');
                        this.fetchItemsFromApi();
                    });
            }
        })
    },
    updateParams(newProps) {
      this.serverParams = { ...this.serverParams, ...newProps };
    },
    onPageChange(params) {
      this.updateParams({ page: params.currentPage });
      this.fetchItemsFromApi();
    },
    onPerPageChange(params) {
      this.updateParams({ perPage: params.currentPerPage });
      this.onPageChange({ currentPage: 1 });
    },
    onSortChange([params]) {
      this.updateParams({
        sort: {
          field: params.field,
          type: params.type,
        },
      });
      this.onPageChange({ currentPage: 1 });
    },
    onColumnFilter(params) {
      this.updateParams(params);
      this.onPageChange({ currentPage: 1 });
    },
    /**
     * Generate params for calls to the API
     */
    getApiParams() {
      const tableSearch = {};
      Object.keys(this.serverParams.columnFilters).forEach((e) => {
        if (!this.serverParams.columnFilters[e]) return;
        if (this.columns.find(column => column.field == e)?.filterOptions?.fullMatch) {
          tableSearch[e] = this.serverParams.columnFilters[e];
        } else {
          tableSearch[`${e}_contains`] = this.serverParams.columnFilters[e];
        }
      });

      return {
        ...tableSearch,
        limit: this.serverParams.perPage,
        page: this.serverParams.page,
        sortBy: `${this.serverParams.sort.field}:${this.serverParams.sort.type}`,
      };
    },
    /**
     * Fetch models from API and display them in the table
     */
    fetchItemsFromApi() {
      this.isTableLoading = true;
      ApiService
        .query("/services", { params: this.getApiParams() })
        .then((res) => {
          this.rows = res.data.results;
          this.totalRecords = res.data.totalResults;
          this.isTableLoading = false;
        })
        .catch((err) => {
          if (err.response.status === 401) this.sessionExpired();
          this.isTableLoading = false;
        });
    },
    /**
     * Export table to CSV file
     */
    exportCsv() {
      this.csvLoading = true;
      ApiService
        .query("/services", { params: { ...this.getApiParams(), toCsv: 1 } })
        .then(({ data }) => {
          const anchor = document.createElement('a');
          anchor.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(data);
          anchor.target = '_blank';
          anchor.download = 'prestations.csv';
          anchor.click();
          this.csvLoading = false;
        })
        .catch(() => {
          this.csvLoading = false;
        });
    },
  },
};
</script>
